.main {
  background-color: #121e30;
}
.footerText {
  color: #ffffff;
  text-decoration: none;
  margin-top: 20px;
}
.footerTextEnd {
  color: #ffffff;
  text-decoration: none;
  margin-top: 25px;
}
.footerNavigation {
  color: #ffffff;
  text-decoration: none;
}
.footerImgText {
  color: #ffffff;
  text-decoration: none;
  margin-top: 6px;
  margin-left: 15px;
}
.footerImageText {
  display: flex;
  margin-top: 15px;
  align-items: center;
}
.topNavText {
  color: #dcdcdc;
  font-size: 15px;
  font-weight: 500;
}
.footerImg {
  background-color: #b7b7b7;
  border-radius: 23px;
  padding: 8px;
  height: 40px;
  width: 40px;
}
.footerHeading {
  color: #ffffff;
  font-size: 22px;
  font-weight: 600;
}
.mainContainer {
  display: flex;
}
.footerGalaryImg {
  height: 80px;
  width: 80px;
  padding: 2px;
}
.footerLogo {
  height: 80px;
  width: 200px;
}
.bottomFooter {
  color: #ffffff;
  background-color: #0b131f;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
}
.footerFirstPart {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 10px;
}
.footerSecondPart {
  margin-top: 55px;
  margin-bottom: 30px;
  margin-right: 10px;
}
.footerThirdPart {
  margin-top: 55px;
  margin-bottom: 30px;
  margin-right: 10px;
}
.footerFourthPart {
  margin-top: 55px;
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .footerThirdPart {
    margin-top: 5px;
    margin-bottom: 30px;
    margin-right: 10px;
  }
  .footerFourthPart {
    margin-top: 5px;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .footerSecondPart {
    margin-top: 5px;
    margin-bottom: 30px;
    margin-right: 10px;
  }
  .footerThirdPart {
    margin-top: 5px;
    margin-bottom: 30px;
    margin-right: 10px;
  }
  .footerFourthPart {
    margin-top: 5px;
    margin-bottom: 30px;
  }
  .navigations {
    margin-top: 20px !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 575px) {
  .footerSecondPart {
    margin-top: 5px;
    margin-bottom: 30px;
    margin-right: 10px;
  }
  .footerThirdPart {
    margin-top: 5px;
    margin-bottom: 30px;
    margin-right: 10px;
  }
  .footerFourthPart {
    margin-top: 5px;
    margin-bottom: 30px;
  }
  .navigations {
    margin-top: 20px !important;
  }
  .footerLogo {
    height: 70px;
    width: 180px;
  }
}
.navigations {
  margin-top: 43px;
}
.subscribeField {
  background: transparent;
  width: 100%;
  height: 40px;
  border: none;
  box-shadow: none;
  padding-left: 8px;
  color: wheat;
}
.subscribeBtn {
  background-color: #3270a0;
  color: #ffffff;
  height: 40px;
  border: none;
  box-shadow: none;
}
.footerGImg {
  display: flex;
}
.footerAllImg {
  margin-top: 20px;
}
.footerBottom {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0px;
}
.items {
  margin-bottom: 20px;
}
