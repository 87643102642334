.transportCard {
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  margin: 8px 0px;
  cursor: pointer;
}

.transportCardHead {
  /* padding: 12px; */
}

.transportCardHead h3 {
  color: #000;
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
  margin-bottom: 0px;
}

.transportCardHead p {
  color: #626262;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 0px;
  width: 100%;
  word-break: break-all;
}

.transportCardHead span {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.transportCardHeadRight {
  text-align: end;
}

.transportCardHeadRight h3 {
  color: #000;
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
  margin-bottom: 0px;
}

.transportCardHeadRight p {
  color: #626262;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 0px;
  width: 360px;
  word-break: break-all;
}

.transportCardHeadRight span {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.divider {
  width: 100%;
  height: 0.5px;
  background: #ddd;
  opacity: 0.25;
  margin-top: 0px;
}

.orderStatusPending {
  color: #ee5600 !important;
}

.orderStatusComplete {
  color: #03aa00 !important;
}

@media (min-width: 320px) and (max-width: 574px) {
  .transportCard {
    border-radius: 5px;
    font-family: "Poppins", sans-serif;
    display: contents;
    align-items: center;
    justify-content: left;
    margin: 50px 50px;
  }
  .transportCardHead {
    padding: 0px;
  }
  .iconsClass {
    display: contents;
    align-items: center;
    gap: 20px;
    margin-top: 0px;
    justify-content: flex-end;
  }
  .transportCardHead h3 {
    color: #000;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
    margin-bottom: 0px;
    margin-top: 25px;
  }
  .divider {
    width: 100%;
    height: 0.5px;
    background: #ddd;
    opacity: 0.25;
    margin-top: 10px;
  }
}
