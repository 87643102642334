.OrderQuot {
  display: block;
  color: #3270a0;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: end;
  margin-top: 12px;
}

.BackToLgnBtn {
  color: #fff;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  padding: 12px;
  border-radius: 5px;
  border: 0.5px solid #6c757d;
  background: #6c757d;
}

.CreateBtn {
  color: #fff;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  padding: 12px;
  border-radius: 5px;
  border: 0.5px solid #3270a0;
  background: #3270a0;
}
.UploadBlock {
  width: 120px;
  height: 120px;
  flex-shrink: 0;
  border-radius: 7px;
  border: 0.5px solid #ddd;
  background: #f3f3f3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

@media screen and (max-width: 400px) {
  .UploadBlock {
    height: 90px;
    width: 90px;
  }
  .UploadBlock img {
    height: 30px;
    width: 30px;
  }
  .signatureField img {
    height: 90px !important;
    width: 90px !important;
  }
}

.UploadBlock p {
  font-size: 12px;
  word-break: normal;
}

.uploadWrapper {
  display: flex;
  gap: 20px;
  margin: 12px 0px;
}

.signatureField {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.signatureField img {
  height: 120px;
  width: 120px;
  border-radius: 7px;
  cursor: pointer;
}

.test .navbar-toggler {
  background-color: white;
  margin-left: 10px;
}

.textBtn {
  margin-top: 25px;
  border: 1px solid #80808094;
  display: flex;
  border-radius: 2px;
}

.HomeAboutSection .row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.HomeAboutSection .row > * {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.selectLabel {
  position: relative;
  top: 15px;
  left: 12px;
  background: white;
  padding: 0px 5px;
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  z-index: 1;
  /* margin-top: -100px; */
}
.LoginInput {
  position: relative;
  margin: 15px 0px 22px;
  height: 45px;
}

.multiSelectInput {
  position: relative;
  margin: 16px 0px 16px;
}

.multiSelectInput label {
  position: absolute;
  top: -12px;
  left: 12px;
  background: white;
  padding: 0px 5px;
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  z-index: 1;
}

.LoginInput label {
  position: absolute;
  top: -12px;
  left: 12px;
  background: white;
  padding: 0px 5px;
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  z-index: 0;
}

.LoginInput .form-control {
  height: 50px;
  border-radius: 5px;
  border: 0.5px solid #ddd;
  background: #fff;
  box-shadow: 0px 4px 9px 0px rgba(130, 130, 130, 0.15);
  padding-right: 40px;
}

.LoginInput .react-tel-input .form-control {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 0.5px solid #ddd;
  background: #fff;
  box-shadow: 0px 4px 9px 0px rgba(130, 130, 130, 0.15);
}

.LoginInput .form-control::placeholder {
  color: #828282;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.LoginInput p {
  padding: 16px 12px;
  height: 50px;
  color: #828282;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 5px;
  border: 0.5px solid #ddd;
  background: #fff;
  box-shadow: 0px 4px 9px 0px rgba(130, 130, 130, 0.15);
  padding-right: 40px;
  cursor: pointer;
}

.LoginInput svg {
  position: absolute;
  top: 13px;
  right: 13px;
  font-size: 25px;
  color: rgba(130, 130, 130, 0.85);
}
.iconsClass {
  /* display: flex; */
  /* align-items: center; */
  /* gap: 20px; */
  margin-top: 15px;
  /* border: 1px solid black; */
}
.iconsClass .iconsClassDelete {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: rgb(251 207 207);
  display: flex;
  align-items: center;
  justify-content: center;
}
.ProctLabel {
  color: #828282;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
}
.ProctUnit {
  color: #101010;

  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ProductUnitBox {
  color: #101010;

  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 2%;
}
.ProductName {
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ProductQuantity1 {
  display: flex;
}
.UnitButton {
  width: 76px;
  height: 35px;
  border-radius: 6px;
  border: 1px solid #eee;

  background: #fff;

  box-shadow: 0px 2px 9px 0px rgba(130, 130, 130, 0.17);
  color: #333;

  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
}
.devider {
  width: 96%;
  height: 0.5px;
  background: #dedede;
  margin: 1%;
  opacity: 0.5;
}

.was-validated .form-control:invalid {
  background-image: none;
}

.was-validated .form-control:valid {
  background-image: none;
}
.listView {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}
.showSignature {
  height: 118px;
  width: 100%;
  border-radius: 5px;
  border: 0.5px solid #ddd;
  background: #fff;
  box-shadow: 0px 4px 9px 0px rgba(130, 130, 130, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
}
.signatureImg {
  height: 100px;
  width: 167px;
  /* /* font-size: 25px; */
}
.dropdownTogle {
  width: 100%;
  color: #959495;
  background-color: #ffffff;
  height: 50px;
  border: 0.5px solid #ddd;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}
