.mainBox {
  background: rgb(230, 238, 244);
  padding-top: 3%;
  padding-bottom: 3%;
  height: auto;
  margin-bottom: -22px;
}

.innerBox {
  width: 60%;
  height: 100%;
  margin-left: 20%;
  margin-right: 20%;
}

.heading {
  display: flex;
  text-align: center;
  margin-left: 0px;
}

.HeadingLabel {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.iconsClass {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 15px;
}

.iconsClass .iconsClassBack {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sectionWrapper {
  padding: 18px 18px 42px 18px;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  margin-top: 1%;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 1%;
}

.sectionContainer {
  border-radius: 5px;
  padding: 32px 18px;
  background-color: #f5f5f5;
}

@media (min-width: 320px) and (max-width: 574px) {
  .heading {
    display: flex;
    text-align: center;
    margin-left: 14px;
  }
  .innerBox {
    width: 100%;
    height: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
}
