.showLogo {
  display: none;
}
.topNavLogo {
  /* background-image: url(../../Assests/Images/top_head.png); */
  /* background-repeat: no-repeat;
  background-position: right; */
  width: 180px;
  height: 72px;
}
.navbarLogoImg {
  height: 72px;
  width: 180px;
  /* margin-left: 85px; */
}
.navItems {
  text-decoration: none;
  color: white;
  /* margin-left: 10px; */
  padding: 10px;
  margin-right: 10px;
}

@media screen and (max-width: 991px) {
  .navLinks {
    padding: 8px;
  }
}
.navItemSelected {
  text-decoration: none;
  background-color: white;
  color: black;
  /* margin-left: 10px; */
  padding: 10px;
  margin-right: 10px;
}
.navBg {
  background-color: #333333;
  padding-top: 10px;
  padding-bottom: 10px;
}
.navToggle .navbar-toggler:focus {
  text-decoration: none !important;
}
.navToggle .navbar-toggler:hover {
  text-decoration: none !important;
}

@media screen and (min-width: 576px) {
  .test {
    margin-left: 85px;
  }
}
.navItems:hover {
  background-color: white;
  color: black;
}
@media screen and (max-width: 991px) {
  .showLogo {
    display: block;
  }
}
