.LogingSection {
  display: flex;
  width: 100%;
}
.LoginImgSec {
  background: url(../Assests/Images/login_bg_img.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px 0px 0px 10px;
  height: auto;
  width: 50%;
}
.LoginImgBackDrop {
  background: url(../Assests/Images/Rectangle\ 43.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px 0px 0px 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.LoginSection {
  width: 50%;
  border-radius: 0px 10px 10px 0px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
  padding: 52px 70px;
}

.LogingSection h3 {
  color: #000;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 60px;
  margin-top: 20px;
}

.ForgotText {
  color: #3270a0;
  text-align: end;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}

.ForgotText span {
  cursor: pointer;
}

.LoginBtn {
  text-align: center;
  padding: 15px;
  margin: 30px 0px;
  border-radius: 5px;
  border: 0.5px solid #3270a0;
  background: #3270a0;
  color: #fff;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: #3270a0;
  border: none;
  width: 100%;
}

.CreateNewTxt p {
  color: #626262;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px; /* 226.667% */
}

.CreateNewTxt span {
  color: #3270a0;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  text-decoration-line: underline;
  cursor: pointer;
}

.LogoImg {
  background: white;
  border-radius: 50%;
  height: 200px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.LoginImgBackDrop h1 {
  color: #fff;
  text-align: center;
  font-size: 45px;
  font-style: normal;
  font-weight: 500;
  line-height: 75px;
}

.CreateBtn button {
  border-radius: 5px;
  background: #fff;
  color: #3270a0;
  text-align: center;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  border: none;
  line-height: normal;
  padding: 12px 20px;
  margin-top: 20px;
}

.SignupSection {
  padding: 50px 100px;
}

.BackBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 110px;
  margin-bottom: 25px;
  cursor: pointer;
}

.BackBtn svg {
  font-size: 45px;
}

.BackBtn span {
  color: #000;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.SignupForm {
  border-radius: 20px;
  background: #fff;
  padding: 40px;
}

.SignupForm h3 {
  color: #000;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 35px;
}

.SignUpBtn {
  margin-top: 25px;
}

.SignUpBtn button {
  color: #fff;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  padding: 12px;
}

.SignUpBtn .BackToLgnBtn {
  border-radius: 5px;
  border: 0.5px solid #6c757d;
  background: #6c757d;
}

.SignUpBtn .CreateBtn {
  border-radius: 5px;
  border: 0.5px solid #3270a0;
  background: #3270a0;
}

@media screen and (max-width: 575px) {
  .LoginSection {
    width: 100%;
    border-radius: 10px 10px 10px 10px;
  }
  .SignupSection {
    padding: 20px 10px !important;
  }
  .SignupForm {
    border-radius: 20px;
    background: #fff;
    padding: 30px;
  }
  .LoginSection h3 {
    font-size: 25px !important;
  }
  .LogingSection {
    display: block;
  }
  .LoginImgSec {
    width: 100%;
    margin-bottom: 12px;
    border-radius: 10px 10px 10px 10px !important;
  }
  .LoginImgBackDrop {
    padding: 22px 22px 50px 22px;
    border-radius: 10px 10px 10px 10px !important;
  }
  .LoginImgBackDrop h1 {
    font-size: 28px !important;
    line-height: 42px !important;
  }
}

@media screen and (max-width: 991px) {
  .LoginSection {
    padding: 45px 30px !important;
  }
  .SignupSection {
    padding: 50px 0px;
  }
}

@media screen and (max-width: 767px) {
  .LoginSection {
    padding: 45px 30px !important;
  }
}

@media screen and (max-width: 767px) {
  .signUpRow {
    gap: 18px;
  }
}

.customsSection p {
  margin-bottom: 0rem !important;
}

.verifyCode {
  font-size: 16px;
  text-align: center;
  margin-top: 22px;
  font-weight: bold;
}

.resendCode {
  font-size: 14px;
  text-align: center;
  margin-top: 22px;
  font-weight: bold;
  color: #3270a0;
  text-decoration: underline;
}
.resendCode span {
  cursor: pointer;
}
