.navbarLogoImg {
  height: 80px;
  width: 200px;
  margin-left: 85px;
}
.navLogoImg {
  padding: 0;
}
.mainNav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.main {
  background-color: #3270a0;
}
.imageText {
  display: flex;
  align-items: center;
  gap: 10px;
}
.topNavImg {
  height: 60px;
  width: 60px;
}
.topNavText {
  color: #dcdcdc;
  font-size: 15px;
  font-weight: 500;
  word-break: break-all;
}
.topNavLogo {
  background-image: url(../../Assests/Images/top_head.png);
  background-repeat: no-repeat;
  background-position: right;
  width: 400px;
  height: 90px;
}
.innerSide {
  margin-right: 50px;
  display: flex;
  gap: 34px;
  margin-right: 80px;
}
.topText {
  margin: 0px;
}
.allImg {
  display: flex;
  align-items: center;
  padding: 0px;
  justify-content: flex-end;
}

.topNavContainer,
.noneMergingClass {
  margin-right: 0px !important;
  margin-left: 0px;
  width: 100%;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .navLogoImg {
    padding: 0;
    display: none;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
  .navbarLogoImg {
    margin-left: 45px;
  }
  .topNavLogo {
    width: 280px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1099px) {
  .navbarLogoImg {
    margin-left: 28px;
  }
  .topNavLogo {
    width: 254px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1040px) {
  .topText {
    margin: 0px;
    font-size: 13px;
  }
  .topNavImg {
    height: 50px;
    width: 50px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .topNavLogo {
    background-image: none;
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navbarLogoImg {
    height: 75px;
    width: 195px;
    margin-left: 85px;
  }
  .allImg {
    display: flex;
    align-items: center;
    padding: 0px;
    justify-content: flex-start;
  }
  .topNavContainer {
    margin-left: 85px;
  }
  .imageText {
    margin: 4px 0px 4px 0px;
  }
  .topText {
    margin: 0px;
    font-size: 14px;
  }
  .topNavImg {
    height: 51px;
    width: 51px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .topNavLogo {
    background-image: none;
    background-color: white;
    width: 100%;
  }
  .navbarLogoImg {
    height: 65px;
    width: 165px;
    margin-left: 85px;
  }
  .allImg {
    display: flex;
    align-items: center;
    padding: 0px;
    justify-content: flex-start;
  }
  .topNavContainer {
    margin-left: 85px;
  }
  .imageText {
    margin: 5px 0px 5px 0px;
  }
  .topNavImg {
    height: 50px;
    width: 50px;
  }
}
@media screen and (min-width: 320px) and (max-width: 575px) {
  .topNavLogo {
    background-image: none;
    background-color: white;
    width: 100%;
  }
  .navbarLogoImg {
    height: 65px;
    width: 165px;
    margin-left: 0px;
  }
  .allImg {
    display: flex;
    align-items: center;
    padding: 0px;
    justify-content: flex-start;
  }
  .imageText {
    margin: 5px 0px 5px 0px;
  }
  .topNavImg {
    height: 50px;
    width: 50px;
  }
}

.localization {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
