.MainBox {
  background-color: #fff;
  width: 100%;
  display: none;
  /* padding-bottom: 90px; */
}
.topNavLogo {
  width: 180px;
  height: 63px;
}
.navbarLogoImg {
  height: 72px;
  width: 180px;
  margin-left: 85px;
}

@media screen and (max-width: 991px) {
  .MainBox {
    background-color: #fff;
    height: 75px;
    width: 100%;
    display: block;
  }
}
