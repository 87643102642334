.MainBox {
  width: 480px;

  flex-shrink: 0;
  border-radius: 20px;
  background: #fff;
  padding-left: 3%;
  padding-top: 2%;
  padding-bottom: 6%;
}
.header {
  display: flex;
  /* width: 100%; */
}
.header p {
  color: #000;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.header img {
  position: absolute;
  right: 0;
  width: 32px;
  height: 32px;
  margin-right: 4%;
}
.devider {
  width: 100%;
  height: 0.5px;
  background: #ddd;
  opacity: 0.5;
}
.alertLabel {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px; /* 180% */
  width: 400px;
  margin: auto;
}
.buttonContainer {
  display: flex;
  width: 400px;
  margin: auto;
  height: 50px;
}
.buttonNo {
  width: 190px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 0.5px solid #6c757d;
  background: #6c757d;
  color: #fff;
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.buttonYes {
  width: 190px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 0.5px solid #3270a0;
  background: #3270a0;
  color: #fff;
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  margin-left: 20px;
}

@media (min-width: 320px) and (max-width: 575px) {
  .MainBox {
    width: 100% !important;
    height: 220px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #fff;
    padding-left: 3%;
    padding-top: 2%;
    /* padding-right: %; */
  }

  .header p {
    color: #000;
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .alertLabel {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 180% */
    width: 100%;
    margin: auto;
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    margin: 0;
    height: auto;
    justify-content: center;
  }

  .buttonNo {
    border: 0.5px solid #6c757d;
    background: #6c757d;
    color: #fff;

    width: 120px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 5px;
    font-family: Poppins;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .buttonYes {
    border: 0.5px solid #3270a0;
    background: #3270a0;
    color: #fff;

    width: 120px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 5px;
    font-family: Poppins;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    margin-left: 20px;
  }
}
