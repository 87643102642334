.suppliersCard {
  border-radius: 5px;
  /* background: #F5F5F5; */
  font-family: "Poppins", sans-serif;
  display: flex;

  justify-content: space-between;
  margin: 2px 0px;
}

.cardFlex {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.suppliersCardHead {
  padding: 12px;
}

.suppliersCardHead h3 {
  color: #000;
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;
  margin-bottom: 0px;
  /* margin-top: 100px; */
}

.suppliersCardHead p {
  color: #626262;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 0px;
  width: 100%;
  word-break: break-all;
}

.suppliersCardHead span {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.suppliersCardHeadRight {
  padding: 12px;
  /* width: 360px; */
  text-align: end;
}

.suppliersCardHeadRight h3 {
  color: #000;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 0px;
}

.suppliersCardHeadRight p {
  color: #626262;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 0px;
  width: 360px;
  word-break: break-all;
}

.suppliersCardHeadRight span {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.AuthorizedClass h3 {
  margin-top: 30px;
  color: #000;
  font-size: 25px;
  font-style: normal;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0px;
}

.devider {
  width: 100%;
  height: 0.5px;
  background: #ddd;
  opacity: 0.25;
  margin-top: 0px;
}

.iconsClass {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-end;
}

.iconsClass .iconsClassReload {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgb(50 112 160 / 16%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconsClass .iconsClassEdit {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #a5a5a554;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconsClass .iconsClassDelete {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgb(251 207 207);
  display: flex;
  align-items: center;
  justify-content: center;
}

.orderStatusPending {
  color: orangered !important;
}

.orderStatusComplete {
  color: #03aa00 !important;
}

.tableDetail {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
}
.totalRecord {
  font-size: 15px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 320px) and (max-width: 574px) {
  .suppliersCard {
    border-radius: 5px;
    font-family: "Poppins", sans-serif;
    display: contents;
    align-items: center;
    justify-content: left;
    margin: 2px 0px;
  }
  .suppliersCardHead {
    padding: 0px;
  }
  .iconsClass {
    display: contents;
    align-items: center;
    gap: 20px;
    margin-top: 0px;
    justify-content: flex-end;
  }
  .devider {
    width: 100%;
    height: 0.5px;
    background: #ddd;
    opacity: 0.25;
    margin-top: 10px;
  }
}
