.TopNavLogo {
  display: none;
}

@media screen and (max-width: 991px) {
  .TopNavLogo {
    display: block;
    cursor: pointer;
  }
}

.NavbarLogoImg {
  height: 60px;
  width: 160px;
}
