/* .break{
    height: 100px;
    background-color: white;
} */

.HomeTopSection {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background: #202937;
  position: relative;
}

.TextCircle {
  position: absolute;
  top: 24px;
  left: 130px;
  width: 512px;
  height: 512px;
  background: #1a3b6d80;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  z-index: 99;
}

.TopText {
  margin-top: 140px;
  margin-left: 70px;
}

.TopTextHead {
  /* display: flex;
  justify-content: center; */
}

.TopTextHead p {
  font-size: 22px;
  line-height: 33px;
  color: #ffff;
  font-weight: 500;
}

.TopDescription h1 {
  font-size: 60px;
  font-weight: 700;
  color: #ffff;
}

.TopImgWrapper {
  width: 70%;
}

.TopImgWrapper img {
  width: 100%;
  height: 560px;
}

.PrevSlickArrow {
  position: absolute;
  bottom: 50px;
  right: 100px;
  z-index: 99;
}

.NextSlickArrow {
  position: absolute;
  bottom: 50px;
  right: 60px;
  z-index: 99;
}

.AboutImg {
  position: relative;
}

.AboutImg img {
  position: absolute;
  right: -65px;
  top: 80px;
  width: 100%;
  max-width: 516px;
}

.AboutDetail {
  background-image: url(../../Assests/Images/Rectangle\ 9.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  /* max-width: 900px; */
  height: 843px;
  display: flex;
  padding-top: 110px;
  padding-left: 100px;
  padding-right: 50px;
}

.AboutCardSection {
  padding: 30px 10px;
}

.AboutTextWrapper h6 {
  color: #1a3b6d;
  font-size: 22px;
}

.AboutTextWrapper h3 {
  color: #202020;
  font-size: 40px;
  font-weight: 600;
  margin: 20px 0px;
}

.AboutTextWrapper p {
  color: #555555;
}
.LogisticDesc {
  display: flex;
  margin-top: 30px;
  align-items: baseline;
}
.LogisticImg {
  background-image: url(../../Assests/Images/Rectangle\ 11.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 190px;
  display: flex;
  padding: 40px;
  align-items: center;
  height: 243px;
}
.LogisticImg span {
  color: #ffffff;
  font-size: 25px;
  font-weight: 600;
  width: 100%;
}

.LogisticDetailWrapper {
  margin-left: 16px;
}
.LogisticDetail {
  display: flex;
}

.LogisticDetail img {
  width: 60px;
  height: 60px;
  margin-right: 12px;
}

.LogisticDetail h5 {
  color: #000000;
  font-size: 20px;
  font-weight: 600;
}

.LogisticDetail p {
  color: #555555;
  font-size: 15px;
  font-weight: 400;
}
.LoadMoreWrapper {
  display: flex;
  margin-top: 60px;
}

.LoadMoreWrapper button {
  width: 190px;
  height: 60px;
  background: #1a3b6d;
  color: #ffff;
  font-size: 20px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
}
.LoadMoreWrapper svg {
  font-size: 28px;
}
.UserProfile {
  margin-left: 16px;
  display: flex;
}
.UserProfileImg img {
  width: 60px;
  height: 60px;
  border: 1px solid #626262;
  border-radius: 50%;
  margin-right: 14px;
}

.UserProfile h6 {
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px;
}

.AboutCompanyCards {
  padding: 30px 20px;
  background: linear-gradient(0deg, #f8f8f8, #f8f8f8);
  border-radius: 8px;
  border: 0.5px solid #d2d2d2;
  margin-bottom: 20px;
}

.AboutCompanyCards img {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}

.AboutCardsDes {
  border-bottom: 0.5px solid #d2d2d2;
}

.AboutCardsDes h6 {
  color: #000;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.AboutCardsDes p {
  color: #333333;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}

.AboutCardsDes hr {
  color: #d2d2d2;
  height: 0.5px;
}

.ReadMore {
  margin-top: 16px;
}
.ReadMore span {
  color: #3270a0;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 12px;
}

.ReadMore svg {
  font-size: 28px;
  color: #3270a0;
}

.WeOfferWrapper {
  background: #0f2343;
  color: #91c8f2;
  margin: 20px 0px 100px;
  padding: 60px 0px 35px;
}

.WeOfferWrapper h6 {
  color: #91c8f2;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.WeOfferWrapper h3 {
  color: #fff;
  font-size: 41px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.WeOfferDes {
  display: flex;
  margin: 24px 0px;
}
.WeOfferDes h5 {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
}
.WeOfferDes img {
  width: 60px;
  height: 60px;
  margin-right: 20px;
}
.WeOfferDes p {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.WeOfferDesWrapper pre {
  width: 360px;
  height: 0.3px;
  flex-shrink: 0;
  background: #828282;
}

.PosterImg {
  position: relative;
}

.PosterImg img {
  position: absolute;
}

.LogisticServiceWrapper {
  margin-top: 20px;
  margin-bottom: 50px;
}

.ServiceHeading {
  margin: 40px 0px;
}
.ServiceHeading h5 {
  color: #0f2343;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.ServiceHeading h3 {
  color: #202020;
  text-align: center;
  font-size: 41px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.ServiceCards h6 {
  color: #202020;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.ServiceCards {
  border-radius: 10px;
  border-bottom: 4px solid rgba(162, 162, 162, 0.67);
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;
  margin-top: 0px;
}
.ServiceCards p {
  color: #333;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}

.ServiceCards pre {
  width: 40px;
  height: 2px;
  flex-shrink: 0;
  background: #3270a0;
}

.ServiceCards span {
  color: #333;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-transform: uppercase;
}

@media (min-width: 1024px) and (max-width: 1254px) {
  .ServiceCards {
    border-radius: 10px;
    border-bottom: 4px solid rgba(162, 162, 162, 0.67);
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    padding: 25px;
    align-items: center;
    margin-top: 10px;
  }
  .AboutImg {
    position: relative;
    /* width: 560px; */
    height: 740px;
    padding: 0px;
  }

  .AboutDetail {
    background-image: url(../../Assests/Images/Rectangle\ 9.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    /* max-width: 900px; */
    height: 800px;
    display: flex;
    /* border: 1px solid black; */
    padding-top: 70px;
    padding-left: 100px;
    /* padding-right: 50px; */
  }

  .LogisticImg {
    background-image: url(../../Assests/Images/Rectangle\ 11.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 171px;
    display: flex;
    padding: 40px;
    align-items: center;
    height: 220px;
  }

  .LogisticImg span {
    color: #ffffff;
    font-size: 22px;
    font-weight: 600;
    width: 100%;
  }

  .LoadMoreWrapper {
    display: flex;
    margin-top: 20px;
  }

  .PosterImg img {
    position: absolute;
    width: 400px;
    height: 700px;
  }
}
@media (min-width: 992px) and (max-width: 1023px) {
  .ServiceCards {
    border-radius: 10px;
    border-bottom: 4px solid rgba(162, 162, 162, 0.67);
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    padding: 25px;
    align-items: center;
    margin-top: 10px;
  }
  .AboutDetail {
    background-image: url(../../Assests/Images/Rectangle\ 9.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    /* max-width: 900px; */
    height: 800px;
    display: flex;
    /* border: 1px solid red; */
    padding-top: 70px;
    padding-left: 100px;
    /* padding-right: 50px; */
  }

  .LogisticImg {
    background-image: url(../../Assests/Images/Rectangle\ 11.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 171px;
    display: flex;
    padding: 40px;
    align-items: center;
    height: 220px;
  }

  .LogisticImg span {
    color: #ffffff;
    font-size: 22px;
    font-weight: 600;
    width: 100%;
  }

  .LoadMoreWrapper {
    display: flex;
    margin-top: 20px;
  }

  .PosterImg img {
    position: absolute;
    width: 400px;
    height: 700px;
  }
}
@media screen and (max-width: 991px) {
  .ServiceCards {
    border-radius: 10px;
    border-bottom: 4px solid rgba(162, 162, 162, 0.67);
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    padding: 25px;
    align-items: center;
    margin-top: 10px;
  }
  .TextCircle {
    left: 75px;
  }
  .AboutImg {
    display: none; /* Hide the AboutImg element */
  }
  .PosterImg {
    display: none;
  }
}

@media screen and (min-width: 767px) and (max-width: 991px) {
  .ServiceCards {
    border-radius: 10px;
    border-bottom: 4px solid rgba(162, 162, 162, 0.67);
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    padding: 25px;
    align-items: center;
    margin-top: 10px;
  }
  .TextCircle {
    left: 75px;
  }
}

@media screen and (min-width: 575px) and (max-width: 767px) {
  .ServiceCards {
    border-radius: 10px;
    border-bottom: 4px solid rgba(162, 162, 162, 0.67);
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    padding: 25px;
    align-items: center;
    margin-top: 10px;
  }
  .AboutDetail {
    background-image: url(../../Assests/Images/Rectangle\ 9.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    /* max-width: 900px; */
    /* height: 900px; */
    display: flex;
    /* border: 1px solid red; */
    padding-top: 70px;
    padding-left: 100px;
    /* padding-right: 50px; */
  }
  .TextCircle {
    left: 40px;
    width: 420px;
    height: 420px;
    top: 70px;
  }
  .TopImgWrapper {
    width: 80%;
  }
  .TopText {
    margin-top: 100px;
    margin-left: 52px;
  }
  .TopDescription h1 {
    font-size: 52px;
  }
  .LogisticDesc {
    display: flex;
    margin-top: 30px;
    align-items: normal;
  }
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .ServiceCards {
    border-radius: 10px;
    border-bottom: 4px solid rgba(162, 162, 162, 0.67);
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    padding: 25px;
    align-items: center;
    margin-top: 10px;
  }
  .TextCircle {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 320px;
    height: 320px;
  }
  .TopText {
    margin-top: 75px;
    margin-left: 50px;
  }
  .TopTextHead p {
    font-size: 16px;
  }
  .TopImgWrapper {
    width: 100%;
  }
  .TopDescription h1 {
    font-size: 38px;
  }
  .AboutDetail {
    background-image: url(../../Assests/Images/Rectangle\ 9.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    /* max-width: 900px; */
    height: auto;
    display: flex;
    padding-top: 50px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 50px;
  }
  .LogisticDesc {
    display: block;
    margin-top: 30px;
    align-items: baseline;
  }
  .LogisticImg {
    background-image: url(../../Assests/Images/Rectangle\ 11.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    display: flex;
    padding: 40px;
    align-items: center;
    text-align: center;
    height: 143px;
  }
  .LogisticDetailWrapper {
    margin-left: 16px;
    margin-top: 16px;
  }
  .LoadMoreWrapper {
    display: flex;
    margin-top: 26px;
  }
  .LoadMoreWrapper button {
    width: 162px;
    height: 48px;
    background: #1a3b6d;
    color: #ffff;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
  }
  .UserProfileImg img {
    width: 48px;
    height: 48px;
    border: 1px solid #626262;
    border-radius: 50%;
    margin-right: 14px;
  }
  .UserProfile h6 {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .UserProfile p {
    color: #000000;
    font-size: 12px;
    /* font-weight: 600; */
    margin-bottom: 0px;
  }
  .ServiceHeading h3 {
    color: #202020;
    text-align: center;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
  .WeOfferDesWrapper pre {
    width: 100%;
    height: 0.3px;
    flex-shrink: 0;
    background: #828282;
  }
}
