.profileTabsLink {
  color: #626262;

  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.profileTabsLinkActive {
  color: #3270a0 !important;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.hrLineP {
  width: 100%;
  height: 1px;
  background: #e2e2e2;
}

.basicInfo {
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.leftBasicInfo {
  color: #626262;
}

.rightBasicInfo {
  color: #000;
  padding: 0px;
}

.rowBasicInfo {
  padding-bottom: 20px;
  /* display: flex; */
}

@media screen and (max-width: 500px) {
  .basicInfo {
    font-size: 15px;
  }
  .rowBasicInfo {
    padding-bottom: 12px;
  }
  .profileTabsLink {
    font-size: 16px;
  }
  .UploadBlock {
    height: 90px !important;
    width: 90px !important;
  }
  .UploadBlock img {
    height: 26px;
    width: 26px;
  }
  .signatureField img {
    height: 90px !important;
    width: 90px !important;
  }
}

.basicInfoTabRow {
  padding: 0px;
}

.CardBody {
  border-radius: 5px;
  background: #f5f5f5;
  padding: 14px;
  margin: 10px 0px;
}

.CardBody h5 {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.CardBody p {
  color: #626262;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.CardBody span {
  color: #333333;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.addMore span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.UploadBlock {
  width: 120px;
  height: 120px;
  flex-shrink: 0;
  border-radius: 7px;
  border: 0.5px solid #ddd;
  background: #f3f3f3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.UploadBlock p {
  font-size: 12px;
  word-break: normal;
}

.uploadWrapper {
  display: flex;
  gap: 20px;
}

.signatureField {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.signatureField img {
  height: 120px;
  width: 200px;
  border: 0.5px solid #ddd;
  border-radius: 7px;
  cursor: pointer;
}

@media (min-width: 320px) and (max-width: 574px) {
  .CardBody h5 {
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .CardBody p {
    color: #626262;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .CardBody span {
    color: #333333;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
.editBtnSection {
  display: flex;
  justify-content: flex-end;
}

.iconsClassEdit {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #a5a5a554;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 0px;
}

.iconsClassDelete {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgb(251 207 207);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 0px;
}

.emailFooter {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.changePassBtn {
  display: flex;
  justify-content: center;
  height: 42px;
}

.BackBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80px;
  margin-bottom: 25px;
  cursor: pointer;
}

.BackBtn svg {
  font-size: 30px;
}

.BackBtn span {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.updateFooter {
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
}

.updateFooter button {
  width: 150px;
}
.tableDetail {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
}
.totalRecord {
  font-size: 15px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
