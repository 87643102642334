.suppliersCard {
  border-radius: 5px;
  background: #f5f5f5;
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 22px 0px;
  cursor: pointer;
}

.tableDetail {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
}
.totalRecord {
  font-size: 15px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .suppliersCard {
    display: block;
    padding: 12px;
  }
  .suppliersCardHead {
    padding: 0px !important;
  }
  .iconsClass {
    justify-content: flex-end;
  }
}

.cardFlex {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.suppliersCardHead {
  padding: 12px;
  width: 100%;
  word-break: break-all;
}

.suppliersCardHead h3 {
  color: #000;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 0px;
}

.suppliersCardHead p {
  color: #626262;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 0px;
  width: 100%;
  word-break: break-all;
}

.suppliersCardHead span {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.suppliersCardHeadRight {
  padding: 12px;
  /* width: 360px; */
  text-align: end;
}

.suppliersCardHeadRight h3 {
  color: #000;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 0px;
}

.suppliersCardHeadRight p {
  color: #626262;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 0px;
  width: 360px;
  word-break: break-all;
}

.suppliersCardHeadRight span {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.AuthorizedClass h3 {
  margin-top: 30px;
  color: #000;
  font-size: 25px;
  font-style: normal;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0px;
}

.iconsClass {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
}

.iconsClass .iconsClassReload {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgb(50 112 160 / 16%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.iconsClass .iconsClassEdit {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #a5a5a554;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.iconsClass .iconsClassDelete {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgb(251 207 207);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* .orderStatusPending {
  color: orangered !important;
}

.orderStatusComplete {
  color: #03aa00 !important;
} */
