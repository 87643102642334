.TabContainer {
  margin-bottom: 1rem !important;
  padding: 14px;
  border: 0.5px solid #d8d8d8;
  border-radius: 10px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: space-between;
}
.tabDesign .nav-fill .nav-item {
  flex: initial;
  text-align: center;
}
.tabDesign .nav .nav-link.active {
  color: white;
  background-color: #3270a0;
  border-color: var(--bs-nav-tabs-link-active-border-color);
  border-radius: 5px;
  border: 0.5px solid #3270a0;
}

.tabDesign .nav-tabs .nav-link {
  background-color: white;
  color: #828282;
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: 0.5px solid #dddddd;
  box-shadow: 0px 4px 9px 0px #82828226;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 500;
  width: 185px;
  /* height: 100%; */
}
.test .navbar-toggler {
  background-color: white;
  margin-left: 10px;
}

.textBtn {
  margin-top: 25px;
  border: 1px solid #80808094;
  display: flex;
  border-radius: 2px;
}

.HomeAboutSection .row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.HomeAboutSection .row > * {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.LoginWrapper {
  background: url(../Images/Rectangle\ 9.png);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0px;
}

@media screen and (max-width: 550px) {
  .LoginWrapper {
    padding: 20px 0px;
  }
}

.LoginInput {
  position: relative;
  margin: 60px 0px 22px;
}
.LoginInputimg {
  position: relative;
  margin: 17px 0px 22px;
}
.LoginInputimg img {
  font-size: 25px;
}

.LoginInput img {
  position: absolute;
  top: 13px;
  right: 13px;
  font-size: 25px;
  color: rgba(130, 130, 130, 0.85);
}

.LoginInput label {
  position: absolute;
  top: -12px;
  left: 12px;
  background: white;
  padding: 0px 5px;
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  z-index: 1;
}
.LoginInputimg label {
  position: absolute;
  top: -12px;
  left: 12px;
  background: white;
  padding: 0px 5px;
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  z-index: 1;
}

.LoginInput .form-control {
  height: 50px;
  border-radius: 5px;
  border: 0.5px solid #ddd;
  background: #fff;
  box-shadow: 0px 4px 9px 0px rgba(130, 130, 130, 0.15);
  /* padding-right: 40px; */
}

.LoginInput .react-tel-input .form-control {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 0.5px solid #ddd;
  background: #fff;
  box-shadow: 0px 4px 9px 0px rgba(130, 130, 130, 0.15);
}

.LoginInput .form-control::placeholder {
  color: #828282;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.LoginInput p {
  padding: 16px 12px;
  height: 50px;
  color: #828282;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 5px;
  border: 0.5px solid #ddd;
  background: #fff;
  box-shadow: 0px 4px 9px 0px rgba(130, 130, 130, 0.15);
  padding-right: 40px;
  cursor: pointer;
}

.LoginInput svg {
  position: absolute;
  top: 13px;
  right: 13px;
  font-size: 25px;
  color: rgba(130, 130, 130, 0.85);
}

.SearchInput .form-control {
  padding-right: 8px !important;
}

.modal-content {
  border-radius: 20px;
  background: #fff;
}

.ForgotPsw .modal-header {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: none;
}

.SignupPopup .modal-title {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.SignupPopup .modal-header svg {
  font-size: 30px;
  color: #828282;
  cursor: pointer;
}

.SignupPopupl .modal-header svg {
  font-size: 30px;
  color: #828282;
  cursor: pointer;
}

.ForgotPsw .modal-title {
  color: #000;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.ForgotPsw .modal-body {
  padding: 0px 50px;
}

.ForgotPsw .modal-body p {
  text-align: center;
  margin-bottom: 0px;
  color: #828282;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 188.889% */
}

.ForgotPsw .modal-body .resetText {
  font-size: 14px;
}

.ForgotPsw .modal-body .LoginInput {
  margin: 40px 0px 0px;
}

.ForgotPsw .modal-footer {
  justify-content: space-between;
  border-top: none;
  padding: 15px 48px 25px;
  width: 100%;
}

@media screen and (max-width: 450px) {
  .ForgotPsw .modal-footer {
    padding: 15px 48px 25px;
  }
}

@media screen and (max-width: 510px) {
  .ForgotPsw .modal-footer .btn-secondary {
    width: 160px !important;
  }
  .ForgotPsw .modal-footer .btn-primary {
    width: 160px !important;
  }
}

@media screen and (max-width: 460px) {
  .ForgotPsw .modal-footer .btn-secondary {
    width: 120px !important;
  }
  .ForgotPsw .modal-footer .btn-primary {
    width: 120px !important;
  }
}
@media screen and (max-width: 400px) {
  .ForgotPsw .modal-footer .btn-secondary {
    width: 100% !important;
  }
  .ForgotPsw .modal-footer .btn-primary {
    width: 100% !important;
  }
  .ForgotPsw .modal-footer {
    padding: 11px 48px;
  }
}

.ForgotPsw .modal-footer .btn-secondary {
  border-radius: 5px;
  border: 0.5px solid #6c757d;
  background: #6c757d;
  color: #fff;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  width: 185px;
  line-height: normal;
  padding: 10px 40px;
}

.ForgotPsw .modal-footer .btn-primary {
  width: 185px;
  border-radius: 5px;
  border: 0.5px solid #3270a0;
  background: #3270a0;
  color: #fff;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px 6px;
}

.SignupPopup .modal-footer {
  justify-content: space-between;
  border-top: none;
  padding: 15px 48px 25px;
  width: 100%;
}

.SignupPopup .modal-footer .btn-secondary {
  border-radius: 5px;
  border: 0.5px solid #6c757d;
  background: #6c757d;
  color: #fff;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  width: 48%;
  line-height: normal;
  padding: 10px 40px;
}

.SignupPopup .modal-footer .btn-primary {
  width: 47%;
  border-radius: 5px;
  border: 0.5px solid #3270a0;
  background: #3270a0;
  color: #fff;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px 6px;
}

.SignupPopup .signupCheckbox {
  display: flex;
  justify-content: space-between;
  margin: 14px 0px;
}

.SignupPopup .signupCheckbox label {
  color: #333;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.SignupWrapper {
  background: rgba(50, 112, 160, 0.12);
}

.SignupWrapper .LoginInput {
  position: relative;
  margin: 25px 0px 22px;
}

.MainPageWrapper {
  background: rgba(50, 112, 160, 0.12);
  padding: 50px 20px;
  display: flex;
  justify-content: space-between;
}

.SidebarWrapper {
  border-radius: 15px;
  background: #333;
  width: 310px;
  padding: 18px;
  height: 100%;
}

@media screen and (max-width: 991px) {
  .MainPageWrapper {
    display: block;
    padding: 20px 20px;
  }
  .SidebarWrapper {
    border-radius: 5px;
    width: auto;
    height: auto;
    padding: 8px 0px 8px 8px;
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;
    background: #ffffff;
  }
  .PageContent {
    margin-left: 0px !important;
  }
  .offcanvas {
    width: 278px !important;
  }
  .offcanvas-body .navbar-nav .nav-link {
    padding: 8px;
    margin-bottom: 12px;
  }
}

.SidebarWrapper .navbar-expand .navbar-nav {
  flex-direction: column;
}

.SidebarWrapper .navbar-expand .navbar-nav .nav-link {
  margin-bottom: 16px;
}

.SidebarWrapper .navbar-nav .nav-link.active {
  margin-bottom: 16px;
  border-radius: 5px;
  background: #fff;
  display: flex;
  min-height: 40px;
  align-items: center;
  justify-content: space-between;
}

.SidebarWrapper .navbar-expand .navbar-nav .nav-link {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

.SidebarWrapper .navbar-expand .navbar-nav .nav-link a:active {
  color: #3270a0;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}

.offcanvas {
  border-radius: 15px;
  background: #333;
  color: white;
}

.offcanvas-header .offcanvas-title {
  font-weight: bolder;
}

.offcanvas-header .btn-close {
  background-color: white;
  font-size: 10px;
  opacity: unset;
  border-radius: 50%;
}

.offcanvas-body .navbar-nav .nav-link {
  font-weight: 500;
}

.offcanvas-body .navbar-nav .active {
  color: #3270a0;
  border-radius: 5px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.offcanvas-body .navbar-nav .nav-link svg {
  display: none;
}

.offcanvas-body .navbar-nav .nav-link.active svg {
  display: block !important;
  color: #3270a0;
  align-items: center;
  justify-content: space-between;
}

.SidebarWrapper .navbar-expand .navbar-nav .nav-link svg {
  display: none;
}

.SidebarWrapper .navbar-nav .nav-link.active svg {
  display: block;
  color: #3270a0;
}

.SidebarWrapper .navbar-nav .nav-link.active {
  color: #3270a0;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}

.PageContent {
  border-radius: 5px;
  background: #fff;
  margin-left: 20px;
  width: 100%;
  padding: 10px;
}

.PageContentHead {
  border-radius: 5px;
  background: #3270a0;
  display: flex;
  justify-content: space-between;
  padding: 14px;
  align-items: center;
}

.PageContentHead h3 {
  color: #fff;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0px;
}

.ContentAddBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ContentAddBtn span {
  margin-left: 10px;
  color: #fff;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media screen and (max-width: 450px) {
  .PageContentHead h3 {
    font-size: 15px;
  }
  .ContentAddBtn svg {
    height: 18px;
    width: 18px;
  }
  .ContentAddBtn span {
    font-size: 15px;
    margin-left: 2px;
  }
}

.ContentBody {
  padding: 14px;
  max-height: 600px;
  overflow-y: auto;
}

.SignupPopup .modal-body .LoginInput {
  margin: 20px 0px 0px;
}

.ContentBody .LoginInput {
  margin: 16px 0px 16px;
}

.ContentBody h5 {
  margin: 16px 0px;
}

.SignupPopup textarea.form-control {
  height: auto !important;
  padding: 10px 12px;
}

.AddPopUp .modal-footer {
  justify-content: flex-end;
  border-top: none;
  padding: 15px 12px 25px;
  width: 100%;
}

.AddPopUp .modal-footer .btn-primary {
  width: 40%;
}

.formInputSpace .LoginInput {
  margin-top: 30px !important;
}

::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 15px #3270a0;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  border-radius: 10px;
}

@media (max-width: 768px) {
  .LoginWrapper .container,
  .LoginWrapper .container-md,
  .LoginWrapper .container-sm {
    max-width: 720px !important;
  }
}

.InputSectionField .LoginInput {
  margin: 12px 0px 0px !important;
}

.ShipmentPopup .modal-header svg {
  font-size: 30px;
  color: #828282;
  cursor: pointer;
}

.ShipmentPopup .LoginInput {
  margin: 0px;
}
.ShipmentPopup .LoginInput input {
  color: black !important;
}

.ShipmentPopup .LoginInput ::placeholder {
  font-size: 12px;
}

.ShipmentPopup .LoginInput label {
  font-size: 14px;
  top: -6px;
  margin: 0px;
}

.ShipmentPopup .modal-footer .btn-secondary {
  border-radius: 5px;
  border: 0.5px solid #6c757d;
  background: #6c757d;
  color: #fff;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  width: 20%;
  line-height: normal;
  padding: 10px 6px;
}

.ShipmentPopup .modal-footer .btn-primary {
  width: 20%;
  border-radius: 5px;
  border: 0.5px solid #3270a0;
  background: #3270a0;
  color: #fff;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px 6px;
}

.addMorePopup .modal-header svg {
  font-size: 30px;
  color: #828282;
  cursor: pointer;
}

.addMorePopup .modal-footer .btn-primary {
  width: 40%;
  border-radius: 5px;
  border: 0.5px solid #3270a0;
  background: #3270a0;
  color: #fff;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px 6px;
}

.transportationFields .LoginInput {
  margin: 4px 0px 22px;
}

.paymentDetail .LoginInput {
  /* position: relative; */
  margin: 0px 0px 22px;
}

.RadioInputSection {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  height: 50px;
  margin: 4px 0px 22px;
  border-radius: 5px;
  border: 0.5px solid #ddd;
  background: #fff;
  box-shadow: 0px 4px 9px 0px rgba(130, 130, 130, 0.15);
}

.RadioLabel {
  color: #000;
  font-family: "Poppins";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 3%;
}

.RadioInputSection .RadioInputs {
  color: #000;
  display: flex;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-weight: bold;
  font-size: 14px;
  margin-left: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 185px;
}

.RadioInputSection .RadioInputs {
  font-weight: bold;
  font-size: 14px;
}

.transportationButton .btn-primary {
  margin-top: 8px;
  height: 50px;
  background-color: #3270a0;
  font-family: "Poppins";
  padding: 0px 20px;
  font-weight: bold;
  float: right;
}

.form-select {
  background-image: url("../../Assests/Images/Vector-Down.svg");
  color: #828282;
  height: 50px;
}

.addMoreSectionField .LoginInput {
  margin: 32px 0px 0px !important;
}

.languageModule .dropdown-toggle {
  background-color: transparent;
  border: none;
}

.validateError {
  color: rgba(255, 0, 0, 0.877);
  font-size: 14px;
  margin-top: 4px;
}

.was-validated .form-control:invalid {
  background-image: none;
}

.was-validated .form-control:valid {
  background-image: none;
}

.form-control:disabled {
  background-color: none !important;
  opacity: 1;
}
.css-13cymwt-control {
  height: 50px;
}
.css-t3ipsp-control {
  height: 50px;
}
.css-lnmdiq5-menu {
  z-index: 2 !important;
}
.css-1xc3v61-indicatorContainer {
  color: rgb(149, 148, 149) !important;
}
/* RESPONSIVE TOP HEADER */
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdownPurchaseOrder .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 7px solid;
  border-right: 7px solid transparent;
  border-bottom: 0;
  border-left: 7px solid transparent;
}
.inValid button {
  border: 0.5px solid #dc3545 !important;
}
/* 
 HEADER ==========================> */
.test .navbar-toggler {
  background-color: #333333;
  margin-right: 11px;
}
@media screen and (max-width: 991px) {
  .BottomHeader_navBg__zm-Sn {
    background-color: #fff;
    /* display: flex;
    justify-content: flex-end; */
  }
  .BottomHeader_navItems__YvrBN {
    text-decoration: none;
    color: #333333;
    padding: 10px;
  }
  .BottomHeader_navItems__YvrBN:hover {
    background-color: #333333;
    color: #fff;
  }
}

.form-control.is-invalid {
  background-image: none;
  border-color: #dc3545;
  padding-right: 10px;
}

.otpbox {
  text-align: center;
  padding: 5px 6px;
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.otpbox input::placeholder {
  font-size: 25px;
  color: #878787;
}

.inputCus input {
  width: 32px !important;
  max-width: 32px;
  height: 37px;
  margin: 16px 0px 16px 27px;
  border-radius: 8px;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  outline: none;
}

.inputStyle {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.otpbox input {
  background-color: #eaeaea;
  border: none;
  border-radius: 10px;
  font-size: 20px;
}

.otpbox input:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

.paginateWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pagination {
  gap: 10px;
  margin-bottom: 0px;
}

.page-link {
  border-radius: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
