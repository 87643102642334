.CloseSubmitBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.ButtonClose {
  border-radius: 5px;
  border: 0.5px solid #6c757d;
  background: #6c757d;
  color: #fff;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  width: 48%;
  line-height: normal;
  padding: 0px 0px !important;
}
