.ContentBody {
  padding: 14px;
  max-height: 600px;
  overflow-y: auto;
}
.CardBody {
  border-radius: 5px;
  background: #f5f5f5;
  /* padding: 14px; */
  padding-top: 20px;
  padding-left: 14px;
  padding-right: 14px;
  margin: 10px 0px;
}

.CardBody h5 {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.CardBody p {
  color: #626262;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.SearchField {
  width: 300px;
  margin-top: 10px;
}

.CardBody span {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.iconsClass {
  /* display: flex; */
  /* align-items: center; */
  /* gap: 20px; */
  margin-top: 15px;
  /* border: 1px solid black; */
}

.iconsClass .iconsClassEdit {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #a5a5a554;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconsClass .iconsClassDelete {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgb(251 207 207);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ResendEmail {
  width: 120px;
  height: 40px;
  margin-top: -20px;
  background: #3270a0;
  border: 1px solid #3270a0;
  font-size: 14px;
}
.tableDetail {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
}
.totalRecord {
  font-size: 15px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 991px) {
  .SearchField {
    width: 100%;
    margin-top: 10px;
  }
}

@media (min-width: 1024px) and (max-width: 1054px) {
  .ResendEmail {
    width: 100px;
    height: 40px;
    margin-top: -20px;
    background: #3270a0;
    border: 1px solid #3270a0;
    font-size: 12px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .iconsClass .iconsClassEdit {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #a5a5a554;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .iconsClass .iconsClassDelete {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgb(251 207 207);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ResendEmail {
    width: 100px;
    height: 35px;
    margin-top: -20px;
    background: #3270a0;
    border: 1px solid #3270a0;
    font-size: 12px;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .SearchField {
    width: 100%;
    margin-top: 10px;
    font-size: 15px;
  }
  .ContentBody {
    padding: 5px;
    max-height: 600px;
    overflow-y: auto;
  }
  .CardBody h5 {
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .CardBody p {
    color: #626262;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .CardBody span {
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .iconsClass .iconsClassEdit {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #a5a5a554;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .iconsClass .iconsClassDelete {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: rgb(251 207 207);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ResendEmail {
    width: 80px;
    height: 30px;
    margin-top: -20px;
    background: #3270a0;
    border: 1px solid #3270a0;
    font-size: 8px;
    font-weight: bolder;
  }
}
