.shipmentWrapper hr {
  opacity: 1.25;
  color: #ddd;
}

.shipmentHeader {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.addContainerSection {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.addContainerSection button {
  background-color: #3270a0;
}

.shipmentProduct {
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
}

.devider {
  width: 100%;
  height: 0.5px;
  background: rgb(68, 65, 65);
  opacity: 0.25;
  margin-top: 0px;
}

@media screen and (max-width: 991px) {
  .shipmentProduct {
    display: block !important;
  }
  .quantityWrapper {
    display: block !important;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .quantityWrapper input {
    width: 100% !important;
  }
  .deleteWrapper {
    display: flex;
    justify-content: flex-end;
  }
}

.shipmentFooter {
  border-top: none;
  margin-top: -25px;
}

@media screen and (max-width: 471px) {
  .shipmentFooter button {
    width: 102px !important;
  }
}

.subShipmentProduct {
  margin-top: 16px;
}

.productCount {
  color: #626262;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.productQuantity {
  color: #828282;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.productBalance {
  margin-top: 8px;
}

.quantityWrapper {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.subQuantityWrapper {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 8px;
}

.shipmentTag {
  padding: 8px 0px;
  display: flex;
  gap: 20px;
}

.shipmentTag span {
  border: 0.5px solid #dddddd;
  border-radius: 5px;
  padding: 8px 20px;
  font-family: "Poppins";
  font-weight: bold;
  gap: 12px;
}

.addMoreSection {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.addMoreSection button {
  background-color: #3270a0;
}

.iconsClassDelete {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgb(251 207 207);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.iconsClassNotDelete {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgb(211, 204, 204);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 445px) {
  .methodFooter button {
    width: 40% !important;
    padding: 6px !important;
  }
}

.requiredAsterisk {
  color: rgba(238, 67, 67, 0.877);
}
