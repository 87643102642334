.InventoryCard {
  margin-bottom: 20px;
}

.InventoryCardHead span {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.InventoryCardHead p {
  color: #626262;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.InventoryCardHead {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.imgSet {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  /* background: #a5a5a554; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: auto;
}

.tableDetail {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* margin-top: 20px; */
}
.totalRecord {
  font-size: 15px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 650px) {
  .InventoryCardHead {
    display: block;
  }
  .InventoryCardHead p {
    margin-top: 10px;
  }
  .iconsClass p {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 450px) {
  .InventoryCardHead p {
    font-size: 15px;
  }
  .InventoryCardHead span {
    font-size: 15px;
  }
  .InventoryCardDes p {
    font-size: 15px !important;
    line-height: 28px !important;
  }
  .InventoryFooterBtn {
    width: 50% !important;
    height: 40px;
    font-size: 15px !important;
  }
}

.InventoryCard hr {
  color: #ddd;
}

.InventoryCardDes p {
  color: #828282;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 37px; /* 185% */
}

.UploadFile {
  margin-top: 16px;
  display: flex;
  align-items: flex-start;
}

.UploadFile span {
  margin-left: 12px;
  color: #3270a0;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.UploadFile label {
  cursor: pointer;
}

.UploadFile .UploadDocs {
  margin-right: 16px;
  /* display: flex; */
  flex-direction: column;
}

.UploadBlock {
  width: 120px;
  height: 120px;
  flex-shrink: 0;
  border-radius: 7px;
  border: 0.5px solid #ddd;
  background: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; /* Optional for text alignment */
  z-index: 0;
  /* margin-top: -25px; */
}
.UploadBlockPreview {
  width: 120px;
  height: 120px;
  flex-shrink: 0;
  border-radius: 7px;
  border: 0.5px solid #ddd;
  background: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; /* Optional for text alignment */
  margin-top: -25px;
  margin-left: 15px;
  z-index: 0 !important;
}
.UploadBlockLabel {
  width: 120px;
  color: #333;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.uploadAttachments {
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
}

.UploadFile .UploadDataSheet small {
  margin-left: 2px;
  margin-top: 10px;
}

.UploadFile input {
  display: none;
}

.iconsClass {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.iconsClass .iconsClassDetails {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgb(193, 230, 193);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.iconsClass .iconsClassEdit {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #a5a5a554;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.iconsClass .iconsClassSwitch {
  width: 35px;
  height: 35px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: auto;
}
.iconsClass .iconsClassDelete {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgb(251 207 207);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.EditDocs {
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
  z-index: 1 !important;
}
.CrossIcon {
  z-index: 1;
  color: red;
  background-color: rgb(225, 225, 225);
}

.requiredAsterisk {
  color: rgba(238, 67, 67, 0.877);
}
