.mainBox {
  background: rgb(230, 238, 244);
  padding-top: 3%;
  padding-bottom: 3%;
  height: auto;
  margin-bottom: -22px;
}

.innerBox {
  width: 60%;
  height: 100%;
  margin-left: 20%;
  margin-right: 20%;
}

.heading {
  display: flex;
  text-align: center;
  margin-left: 0px;
  /* width: 235px; */
  /* height: 45px; */
  /* top: 135px; */
  /* left: 190px; */
}

.HeadingLabel {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.iconsClass {
  display: flex;
  align-items: center;
  /* gap: 20px; */
  justify-content: flex-end;
  margin-right: 15px;
  /* margin-top: 10px; */
}

.iconsClass .iconsClassBack {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.billing {
  width: 100%;
  height: 100%;
  margin-top: 1%;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 1%;
  padding-bottom: 1%;
}

.orderAndMethod {
  display: flex;
  height: 100px;
  width: 90%;
  margin: 3%;
}

.orderAndMethodBlocks {
  height: 100%;
  width: 40%;
  margin-right: 20px;
  margin-top: 25px;
}

.orderType {
  color: #000;
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.optionButton {
  display: flex;
  margin-top: 15px;
  color: #fff;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.optionButtonButton1 {
  background-color: #3270a0;
  border-color: #3270a0;
  margin-right: 2%;
  width: 120px;
  height: 40px;
}

.optionButtonButton2 {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  margin-right: "2%";
  color: black;
  width: 120px;
  height: 40px;
}

.ShippingDetails {
  margin: 3%;
  color: #000;
  padding-top: 20px;
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.billingBox {
  margin: 3%;
  width: 94%;
  height: auto;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding-bottom: 3%;
}

.bookingDetail {
  width: 94%;
  height: auto;
  margin-left: 3%;
  display: flex;
  padding-top: 30px;
}

.bookingDetailLeft {
  height: 100%;
  width: 50%;
}

.bookingDetailRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: left;
  height: 100%;
  width: 50%;
  margin-top: 30px;
}

.bookingDetailRightFlex {
  display: flex;
}

.bookingDetailRight_key {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  color: #626262;
  text-align: right;
}

.bookingDetailRight_value {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  color: #000;
  text-align: right;
}

.devider {
  width: 96%;
  height: 0.5px;
  background: #ddd;
  margin: 2%;
  opacity: 0.5;
}

.BLorbooking {
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
}

.BLorbooking_key {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  color: #626262;
}

.BLorbooking_value {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  color: #000;
}

.BLorbooking_value_right {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  color: #000;
  margin-left: 5px;
}

.bookingDetailRightFlexImgRight {
  margin-top: 0px;
  margin-left: 10px;
}

.bookingDetailRightFlexImgLeft {
  margin-top: -20px;
  margin-left: 5px;
}

.productBox {
  display: flex;
  width: 94%;
  margin-left: 3%;
  height: 20px;
}

.productId1 {
  height: 38px;
  width: 25%;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.productId2 {
  height: 100%;
  width: 35%;
}

.productId3 {
  height: 100%;
  width: 40%;
}

.booking {
  height: 100%;
  width: 25%;
  word-break: break-all;
}

.productTitle {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  color: #333;
}

.productQuantity {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  color: #828282;
}

.quantity {
  height: 100%;
  width: 25%;
  word-break: break-all;
}

.balance {
  height: 100%;
  width: 25%;
  text-align: right;
  word-break: break-all;
}

.orderid {
  height: 100%;
  width: 25%;
  text-align: right;
}

.productBox2 {
  display: flex;
  width: 94%;
  margin: 3%;
  height: auto;
}

.container_holds_product {
  margin-bottom: 30px;
  margin-top: 30px;
}

.documentHolder {
  width: 96%;
  margin: 3%;
  height: auto;
}

.documentColumn {
  display: flex;
  width: 95%;
  height: 70px;
  background-color: white;
  border-radius: 3%;
  box-shadow: 0px 4px 9px 0px rgba(130, 130, 130, 0.15);
}

.documentName {
  display: flex;
  height: 100%;
  width: 70%;
  align-items: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 2%;
}

.warning {
  color: #e8112d;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.imgSet {
  display: flex;
  align-items: center;
  text-align: left;
  margin-left: 2%;
  margin-right: 2%;
}

.transportationFields {
  padding: 8px 22px;
}

.billDetail {
  /* border: 1px solid black; */
  /* width: 100%; */
  /* height: 100px; */
  margin: 3%;
}
.declaration {
  width: 100%;
  /* height: 20px; */
  /* border: 1px solid black; */
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.keyheading {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  color: #333;
  margin-left: 4px;
  /* text-align: left; */
}
.keyValue {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  color: #828282;

  /* text-align: left; */
}

@media (min-width: 1024px) and (max-width: 1254px) {
  .productId1 {
    height: 38px;
    width: 25%;
    font-family: Poppins;
    margin-bottom: 2%;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }

  .BLorbooking {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
  }
  .BLorbooking_key {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0em;
    color: #626262;
  }
  .BLorbooking_value {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #000;
  }
  .BLorbooking_value_right {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    color: #000;
    margin-left: 5px;
  }
  .productTitle {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    /* line-height: 13px; */
    letter-spacing: 0em;
    color: #333;
  }
  .productQuantity {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
    color: #828282;
  }
  .orderid {
    height: 10%;
    width: 25%;
    text-align: right;
  }
  .orderType {
    color: #000;
    font-family: Poppins;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .ShippingDetails {
    margin: 3%;
    color: #000;
    padding-top: 15px;
    font-family: Poppins;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .documentHeadind {
    height: 38px;
    width: 50%;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
  .documentName {
    display: flex;
    height: 100%;
    width: 70%;
    align-items: center;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 2%;
  }
}

@media (min-width: 983px) and (max-width: 1023px) {
  .productId1 {
    height: 38px;
    width: 25%;
    font-family: Poppins;
    /* margin-bottom: 2%; */
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
  }
  .BLorbooking {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
  }
  .BLorbooking_key {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 10px;
    letter-spacing: 0em;
    color: #626262;
  }
  .BLorbooking_value {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 10px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #000;
  }
  .BLorbooking_value_right {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0em;
    color: #000;
    margin-left: 5px;
  }
  .productTitle {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 10px;
    letter-spacing: 0em;
    color: #333;
  }
  .productQuantity {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0em;
    color: #828282;
  }
  .orderType {
    color: #000;
    font-family: Poppins;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .ShippingDetails {
    margin: 3%;
    color: #000;
    padding-top: 15px;
    font-family: Poppins;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .documentHeadind {
    height: 38px;
    width: 50%;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
  }
  .documentName {
    display: flex;
    height: 100%;
    width: 70%;
    align-items: center;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 2%;
  }
}

@media (min-width: 767px) and (max-width: 982px) {
  .optionButtonButton1 {
    background-color: #3270a0;
    border-color: #3270a0;
    margin-right: 2%;
    width: 108px;
    height: 36px;
  }
  .productId1 {
    height: 38px;
    width: 25%;
    font-family: Poppins;
    /* margin-bottom: 2%; */
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
  }
  .BLorbooking {
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0em;
  }
  .BLorbooking_key {
    font-family: Poppins;
    font-size: 9px;
    font-weight: 500;
    line-height: 9px;
    letter-spacing: 0em;
    color: #626262;
  }
  .BLorbooking_value {
    font-family: Poppins;
    font-size: 9px;
    font-weight: 500;
    line-height: 9px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #000;
  }
  .BLorbooking_value_right {
    font-family: Poppins;
    font-size: 9px;
    font-weight: 500;
    line-height: 9px;
    letter-spacing: 0em;
    color: #000;
    margin-left: 5px;
  }
  .productTitle {
    font-family: Poppins;
    font-size: 9px;
    font-weight: 500;
    line-height: 9px;
    letter-spacing: 0em;
    color: #333;
  }
  .productQuantity {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 10px;
    letter-spacing: 0em;
    color: #828282;
  }
  .orderType {
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .ShippingDetails {
    margin: 3%;
    color: #000;
    padding-top: 15px;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .booking {
    height: 100%;
    width: 25%;
  }
  .quantity {
    height: 100%;
    width: 30%;
  }

  .balance {
    height: 100%;
    width: 30%;
    text-align: right;
  }
  .orderid {
    height: 100%;
    width: 20%;
    text-align: right;
  }
  .documentName {
    display: flex;
    height: 100%;
    width: 70%;
    align-items: center;
    font-family: Poppins;
    font-size: 11px;
    font-weight: 500;
    line-height: 11px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 2%;
  }
  .DocsHeading {
    height: 38px;
    width: 50%;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }

  .documentColumn {
    display: flex;
    width: 95%;
    height: 63px;
    background-color: white;
    border-radius: 3%;
    box-shadow: 0px 4px 9px 0px rgba(130, 130, 130, 0.15);
  }
}

@media (min-width: 575px) and (max-width: 766px) {
  .optionButton {
    display: flex;
    margin-top: 1px;
    color: #fff;
  }

  .optionButtonButton1 {
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: #3270a0;
    border-color: #3270a0;
    margin-right: 2%;
    width: 72px;
    height: 24px;
  }

  .orderType {
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .ShippingDetails {
    margin: 3%;
    color: #000;
    padding-top: 0px;
    font-family: Poppins;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .HeadingLabel {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-top: 10px;
  }

  .iconsClass {
    display: flex;
    align-items: center;
    /* gap: 20px; */
    justify-content: flex-end;
    /* margin-right: 15px; */
    margin-top: 10px;
  }
  .iconsClass .iconsClassReload {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .productId1 {
    height: 38px;
    width: 25%;
    font-family: Poppins;
    /* margin-bottom: 2%; */
    font-size: 10px;
    font-weight: 500;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
  }
  .BLorbooking {
    color: #000;
    font-family: Poppins;
    font-size: 8px;
    font-weight: 500;
    line-height: 8px;
    letter-spacing: 0em;
  }
  .BLorbooking_key {
    font-family: Poppins;
    font-size: 7px;
    font-weight: 500;
    line-height: 7px;
    letter-spacing: 0em;
    color: #626262;
  }
  .BLorbooking_value {
    font-family: Poppins;
    font-size: 7px;
    font-weight: 500;
    line-height: 7px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #000;
  }
  .BLorbooking_value_right {
    font-family: Poppins;
    font-size: 8px;
    font-weight: 500;
    line-height: 8px;
    letter-spacing: 0em;
    color: #000;
    margin-left: 5px;
  }
  .productTitle {
    font-family: Poppins;
    font-size: 7px;
    font-weight: 500;
    line-height: 7px;
    letter-spacing: 0em;
    color: #333;
  }
  .productQuantity {
    font-family: Poppins;
    font-size: 7px;
    font-weight: 500;
    line-height: 7px;
    letter-spacing: 0em;
    color: #828282;
  }

  .ShippingDetails {
    margin: 3%;
    color: #000;
    /* padding-top: 15px; */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .booking {
    height: 100%;
    width: 25%;
  }
  .quantity {
    height: 100%;
    width: 30%;
  }

  .balance {
    height: 100%;
    width: 30%;
    text-align: right;
  }
  .orderid {
    height: 100%;
    width: 20%;
    text-align: right;
  }

  .documentName {
    display: flex;
    height: 100%;
    width: 70%;
    align-items: center;
    font-family: Poppins;
    font-size: 11px;
    font-weight: 500;
    line-height: 11px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 2%;
  }
  .DocsHeading {
    height: 38px;
    width: 50%;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  .documentColumn {
    display: flex;
    width: 95%;
    height: 63px;
    background-color: white;
    border-radius: 3%;
    box-shadow: 0px 4px 9px 0px rgba(130, 130, 130, 0.15);
  }
  .ShippingDetails {
    margin: 3%;
    color: #000;
    /* padding-top: 20px; */
    font-family: Poppins;
    /* font-size: 21px; */
    font-style: normal;
    font-weight: 600;
    /* line-height: normal; */
  }
  .bookingDetailRightFlexImgLeft {
    margin-top: -20px;
    margin-left: 5px;
    width: 14px;
    height: 14px;
  }
}

@media (min-width: 320px) and (max-width: 574px) {
  .heading {
    display: flex;
    text-align: center;
    margin-left: 14px;
    /* width: 235px; */
    /* height: 45px; */
    /* top: 135px; */
    /* left: 190px; */
  }
  .innerBox {
    width: 573px;
    height: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
  .optionButton {
    display: flex;
    margin-top: 1px;
    color: #fff;
  }

  .optionButtonButton1 {
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: #3270a0;
    border-color: #3270a0;
    margin-right: 2%;
    width: 72px;
    height: 24px;
  }

  .orderType {
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .ShippingDetails {
    margin: 3%;
    color: #000;
    padding-top: 0px;
    font-family: Poppins;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .HeadingLabel {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-top: 10px;
  }

  .iconsClass {
    display: flex;
    align-items: center;
    /* gap: 20px; */
    justify-content: flex-end;
    /* margin-right: 15px; */
    margin-top: 5px;
  }
  .iconsClass .iconsClassReload {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .productId1 {
    height: 38px;
    width: 25%;
    font-family: Poppins;
    /* margin-bottom: 2%; */
    font-size: 10px;
    font-weight: 500;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
  }
  .BLorbooking {
    color: #000;
    font-family: Poppins;
    font-size: 8px;
    font-weight: 500;
    line-height: 8px;
    letter-spacing: 0em;
  }
  .BLorbooking_key {
    font-family: Poppins;
    font-size: 7px;
    font-weight: 500;
    line-height: 7px;
    letter-spacing: 0em;
    color: #626262;
  }
  .BLorbooking_value {
    font-family: Poppins;
    font-size: 7px;
    font-weight: 500;
    line-height: 7px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #000;
  }
  .BLorbooking_value_right {
    font-family: Poppins;
    font-size: 8px;
    font-weight: 500;
    line-height: 8px;
    letter-spacing: 0em;
    color: #000;
    margin-left: 5px;
  }
  .productTitle {
    font-family: Poppins;
    font-size: 7px;
    font-weight: 500;
    line-height: 7px;
    letter-spacing: 0em;
    color: #333;
  }
  .productQuantity {
    font-family: Poppins;
    font-size: 7px;
    font-weight: 500;
    line-height: 7px;
    letter-spacing: 0em;
    color: #828282;
  }

  .ShippingDetails {
    margin: 3%;
    color: #000;
    /* padding-top: 15px; */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .booking {
    height: 100%;
    width: 25%;
  }
  .quantity {
    height: 100%;
    width: 30%;
  }

  .balance {
    height: 100%;
    width: 30%;
    text-align: right;
  }
  .orderid {
    height: 100%;
    width: 20%;
    text-align: right;
  }

  .documentName {
    display: flex;
    height: 100%;
    width: 70%;
    align-items: center;
    font-family: Poppins;
    font-size: 11px;
    font-weight: 500;
    line-height: 11px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 2%;
  }
  .DocsHeading {
    height: 38px;
    width: 50%;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  .documentColumn {
    display: flex;
    width: 95%;
    height: 63px;
    background-color: white;
    border-radius: 3%;
    box-shadow: 0px 4px 9px 0px rgba(130, 130, 130, 0.15);
  }
  .ShippingDetails {
    margin: 3%;
    color: #000;
    /* padding-top: 20px; */
    font-family: Poppins;
    /* font-size: 21px; */
    font-style: normal;
    font-weight: 600;
    /* line-height: normal; */
  }
  .bookingDetailRightFlexImgLeft {
    margin-top: -20px;
    margin-left: 5px;
    width: 14px;
    height: 14px;
  }
}
