.mainBox {
  /* zzzzzzzz */
  background: rgb(230, 238, 244);
  padding-top: 3%;
  padding-bottom: 3%;
  height: auto;
  margin-bottom: -22px;
  padding-left: 5%;
}
.innerBox {
  /* zzzzzzzzz */
  width: 95%;
  height: 100%;
  /* margin-left: 20%;
  margin-right: 20%; */
}
.heading {
  /* zzzzzzz */
  display: flex;
  width: 235px;
  height: 45px;
  top: 135px;
  left: 190px;
}
.iconsClass {
  /* zzzzzzz */
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-end;
  margin-right: 15px;
  margin-top: 10px;
}
.iconsClass .iconsClassReload {
  /* zzzzzzz */
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.HeadingLabel {
  /* zzzzzzz */
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.billing {
  /* zzzzzzzzz */
  width: 100%;
  height: 100%;
  margin-top: 1%;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 1%;
  padding: 3%;
}
.billingBox {
  width: 100%;
  height: auto;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 4%;
  padding-bottom: 4%;
}

.labels {
  display: flex;
  justify-content: space-between;
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 20px;
}
.labels p {
  width: 76%;
}
@media (min-width: 320px) and (max-width: 574px) {
  .innerBox {
    /* zzzzzzzzz */
    width: 100%;
    height: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
}
