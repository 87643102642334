.OrderDetailsWrapper {
  background: rgba(50, 112, 160, 0.12);
}

.OrderDetailsSection {
  padding: 50px 20px;
}

.OrderDetailsSection hr {
  color: #ddd;
}

.OrderDetailsSection svg {
  font-size: 45px;
}

.OrderDetails {
  border-radius: 5px;
  background: #fff;
  padding: 28px 20px;
}

.OrderDetailsHeader {
  font-weight: bolder;
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
}

.subHeader {
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
}

.SuppliersInfoHeader {
  font-weight: bold;
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
}

.SuppliersInfoTop {
  padding-top: 25px;
}

.SuppliersInfo {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
}

.SuppliersInfo span {
  padding: 8px 30px;
  background-color: #f5f5f5;
  border-radius: 5px;
  font-size: 18px;
}

.OrderTypeTop {
  padding-top: 20px;
}

.OrderTypeHeader {
  font-weight: bold;
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
}

.OrderType {
  margin-top: 18px;
  display: flex;
  justify-content: flex-start;
  gap: 15px;
}

.OrderType span {
  padding: 8px 36px;
  border-radius: 5px;
  font-size: 18px;
  width: 145px;
  display: flex;
  justify-content: center;
}

.orderComplete {
  background-color: #3270a0;
  color: white;
}

.orderSplit {
  background-color: #f5f5f5;
}

.BackToList {
  margin-bottom: 25px;
}

.backSection {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
}

.iconsClass {
  display: flex;
  align-items: center;
  margin-top: 8px;
  justify-content: space-between;
  gap: 10px;
}

.iconsClass .iconsClassReload {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgb(50 112 160 / 16%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.headerSection {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
}

.headerText {
  font-weight: 600;
  font-size: 25px;
  font-family: "Poppins";
}

.pendingWrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: flex-end;
  justify-content: flex-end;
}

.pending {
  background-color: #ebdcd3;
  color: #ee5600;
  padding: 8px 12px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 18px;
}

.listHeader p {
  color: #626262;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 0px;
  padding: 6px 0px;
}

.listHeader span {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.orderAmount {
  font-weight: 600;
  /* float: right; */
  font-size: 22px;
}

.iconsClass .iconsClassEdit {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #a5a5a554;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.iconsClass .iconsClassDelete {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  /* padding-top: 10px; */
  background: rgb(251 207 207);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.inventorySectionHead {
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  margin-top: 16px;
}

.inventorySectionTop {
  margin: 22px 0px 22px 0px;
}

.inventorySectionTop hr {
  color: #b8b8b8;
  margin-top: 12px;
}

/* .inventorySection {
  display: flex;
  justify-content: space-between;
} */

/* .inventorySubSection {
  margin-top: 20px;
} */

/* .inventorySubSection span {
  padding: 12px 106px;
  background-color: #f5f5f5;
  border-radius: 5px;
  font-size: 18px;
} */

.inventorySection span {
  display: flex;
  padding: 8px 106px;
  background-color: #f5f5f5;
  border-radius: 5px;
  gap: 15px;
  font-size: 18px;
}

/* .infoPoints {
  padding: 8px 36px;
  background-color: #dddddd;
  border-radius: 5px;
} */

.variationSection {
  display: flex;
  justify-content: space-between;
}

.variationSectionTop {
  margin-top: 26px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.variationSubtitle {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: bold;
}

.variationSection p {
  color: #626262;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 0px;
  padding: 8px 0px;
}

.variationSectionContainer {
  padding: 16px 12px;
}

.variationSection span {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.shipmentType {
  display: flex;
  justify-content: flex-start;
  gap: 38px;
  margin-top: 12px;
  padding: 9px 0px 0px 21px;
}

@media screen and (max-width: 780px) {
  .shipmentType {
    flex-direction: column;
  }
  .shipmentType span {
    justify-content: center !important;
  }
  .headerText {
    font-size: 20px;
  }
  .variationSection {
    display: block;
  }
}

.selectShipment {
  background-color: #3270a0;
  color: white;
  padding: 8px 20px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}

.shipmentText {
  cursor: pointer;
}

.shipmentOption {
  background-color: white;
}

/* .shipmentType span {
  display: flex;
  justify-content: normal;
  padding: 11px 33px;
  border-radius: 5px;
  gap: 15px;
  width: 100%;
  font-size: 18px;
} */

.productTitle {
  color: #828282;
  font-size: 17px;
  font-family: "Poppins";
  font-weight: 500;
  margin-bottom: 5px;
}

.productValue {
  color: #000000;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 500;
}
.submitSection {
  display: flex;
  justify-content: flex-end;
  margin: 28px 0px 0px 0px;
}
.submitButton {
  background-color: #3270a0;
  padding: 8px 48px;
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 600;
}

.addShipmentBtn {
  margin-top: 18px;
  float: right;
}
