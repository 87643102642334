.suppliersCard {
  border-radius: 5px;
  /* background: #F5F5F5; */
  font-family: "Poppins", sans-serif;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  margin: 2px 0px;
}

.cardFlex {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.suppliersCardHead {
  padding: 12px;
}
.suppliersCardHeadRight {
  padding: 12px;
  text-align: end;
}

.suppliersCardHead h3 {
  color: #000;
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;
  margin-bottom: 0px;
  /* margin-top: 100px; */
}

.suppliersCardHead p {
  color: #626262;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 0px;
  width: 100%;
  word-break: break-all;
  /* border: 1px solid black; */
}
.suppliersCardHeadRight p {
  color: #626262;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 0px;
  width: 360px;
  word-break: break-all;
  /* border: 1px solid black; */
}
.suppliersCardHead span {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.suppliersCardHeadRight span {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.AuthorizedClass h3 {
  margin-top: 30px;
  color: #000;
  font-size: 25px;
  font-style: normal;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0px;
}

.devider {
  width: 100%;
  height: 0.5px;
  background: rgb(68, 65, 65);
  opacity: 0.25;
  margin-top: 0px;
}

.iconsClass {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
  justify-content: flex-end;
}

.iconsClass .iconsClassReload {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgb(50 112 160 / 16%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconsClass .iconsClassEdit {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #a5a5a554;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconsClass .iconsClassDelete {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgb(251 207 207);
  display: flex;
  align-items: center;
  justify-content: center;
}

.orderStatusPending {
  color: orangered !important;
}

.orderStatusComplete {
  color: #03aa00 !important;
}

.LoginInput {
  position: relative;
  margin-top: 10%;
  width: 100%;
}

.LoginInput label {
  position: absolute;
  top: -10px;
  left: 12px;
  background: white;
  padding: 0px 5px;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  z-index: 1;
}

.LoginInput .form-control {
  height: 50px;
  border-radius: 5px;
  border: 0.5px solid #ddd;
  background: #fff;
  box-shadow: 0px 4px 9px 0px rgba(130, 130, 130, 0.15);
  padding-right: 40px;
}

.LoginInput .react-tel-input .form-control {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 0.5px solid #ddd;
  background: #fff;
  box-shadow: 0px 4px 9px 0px rgba(130, 130, 130, 0.15);
}

.LoginInput .form-control::placeholder {
  color: #828282;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.LoginInput p {
  padding: 16px 12px;
  height: 50px;
  color: #828282;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 5px;
  border: 0.5px solid #ddd;
  background: #fff;
  box-shadow: 0px 4px 9px 0px rgba(130, 130, 130, 0.15);
  padding-right: 40px;
  cursor: pointer;
}

.LoginInput svg {
  position: absolute;
  top: 8px;
  right: 13px;
  font-size: 25px;
  /* color: rgba(130, 130, 130, 0.85); */
}

@media (min-width: 320px) and (max-width: 574px) {
  .suppliersCard {
    border-radius: 5px;
    font-family: "Poppins", sans-serif;
    display: contents;
    align-items: center;
    justify-content: left;
    margin: 2px 0px;
  }
  .suppliersCardHead {
    padding: 0px;
  }
  .iconsClass {
    display: contents;
    align-items: center;
    gap: 20px;
    margin-top: 0px;
    justify-content: flex-end;
  }
  .devider {
    width: 100%;
    height: 0.5px;
    background: #ddd;
    opacity: 0.25;
    margin-top: 10px;
  }
}
