.suppliersCard {
  border-radius: 5px;
  background: #f5f5f5;
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 22px 0px;
}

.cardFlex {
  flex-direction: column;
  gap: 12px;
}

@media screen and (max-width: 850px) {
  .cardFlex {
    display: block;
  }
  .suppliersCard {
    display: block;
    padding: 12px;
  }
  .suppliersCardHead {
    padding: 0px !important;
  }
}

.suppliersCardHead {
  padding: 12px;
}

.suppliersCardHead h3 {
  color: #000;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 0px;
}

.suppliersCardHead p {
  color: #626262;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 0px;
}

.suppliersCardHead span {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.AuthorizedClass h3 {
  margin-top: 30px;
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0px;
}
.iconsClass {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.iconsClass .iconsClassDetails {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgb(193, 230, 193);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.iconsClass .iconsClassEdit {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #a5a5a554;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.iconsClass .iconsClassDelete {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgb(251 207 207);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.contactBox {
  display: flex;
}

.contactBox span {
  margin-left: 6px;
}

@media screen and (max-width: 412px) {
  .supplierBtn {
    font-size: 16px !important;
    width: 60% !important;
  }
  .contactBox {
    display: block;
  }
  .AuthorizedClass h3 {
    margin-top: 30px;
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0px;
  }
}

.requiredAsterisk {
  color: rgba(238, 67, 67, 0.877);
}

.countryField svg {
  position: unset;
}

.flagSelect button {
  height: 50px;
}
